
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { VueCookieNext } from "vue-cookie-next";
import ApiService from "@/core/services/ApiService";
import PasswordResetVue from "../authentication/PasswordReset.vue";
import { apiEndpoint } from "@/mixin/apiMixin.js";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "user-changePassword",
  data() {
    return {
      api_url: "",
      showPassform: false,
      employee: {
        id: "",
        name: "",
        designation: "",
        nid: "",
        passport: "",
        driving_license: "",
        dob: "",
        email: "",
        mobile: "",
        present_address: "",
        present_district: "",
        permanent_address: "",
        permanent_district: "",

        religion: "",
        gender: "",
      },
      userInfo: {
        employee: {
          entity_organogram: [],
        } as any,
      },
      userPass: {
        currentPass: "",
        newPass: "",
        confirmPass: "",
      },
      loading: false,
    };
  },

  components: {
    ErrorMessage,
    Field,
    Form,
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    this.userInfo = VueCookieNext.getCookie("_seip_user");
    await this.getEmployeeProfile();
  },
  methods: {
    async formSubmit() {
      this.loading = true;
      if (this.userPass.newPass === this.userPass.confirmPass) {
        let formData = new FormData();
        formData.set("user_id", this.userInfo.employee.user_id);
        formData.set("name", this.employee.name);

        for (var key in this.userPass) {
          formData.set(key, this.userPass[key]);
        }
        this.loading = true;
        await ApiService.post(
          "configurations/employee/userPassChange",
          formData
        )
          .then((response) => {
            console.log(response);
            this.loading = false;
            if (response.data.status === "success") {
              Swal.fire({
                title: "Success!",
                text: response.data.data,
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              }).then(() => {
                // clear cookies + logout + redirect signin page

                this.signout();
              });
            } else if (response.data.status === "error") {
              Swal.fire({
                title: "Error",
                text: response.data.data,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Close",
                customClass: {
                  confirmButton: "btn btn-danger",
                },
              });
            }
          })
          .catch(({ response }) => {
            console.log("error e dukce" + response);
            this.loading = false;
            Swal.fire({
              title: "Unknown error",
              html: response.data.data,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
            console.log(response);
          });
      } else {
        this.loading = false;
        Swal.fire({
          title: "Submit Failed!",
          text: "Password Not Match!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Close",
          customClass: {
            confirmButton: "btn btn-danger",
          },
        });
      }
    },
    async getEmployeeProfile() {
      await ApiService.get(
        "configurations/employee/show/" + this.userInfo.employee.id
      )
        .then((response) => {
          this.employee = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    signout() {
      localStorage.clear();
      VueCookieNext.removeCookie("_seip_user");
      VueCookieNext.removeCookie("_seip_employee_id");
      VueCookieNext.removeCookie("_entity_id");
      VueCookieNext.removeCookie("_institute_info_id");
      VueCookieNext.removeCookie("_seip_role_id");
      VueCookieNext.removeCookie("_seip_entity_type");
      VueCookieNext.removeCookie("_seip_token");
      this.$router.push("/sign-in");
    },

    async passwordFormDisplay() {
      this.showPassform = true;
    },
    async passBack() {
      this.showPassform = false;
      // console.log(this.userInfo.employee.user_id);
    },
  },
});
